const Cookies = require("js-cookie")

exports.onClientEntry = () => {
  window.onload = () => {
    try {
      // Analytical cookies are allowed
      if (
        localStorage.getItem("cookiePermissions") &&
        JSON.parse(localStorage.getItem("cookiePermissions")).analytical
      ) {
        localStorage.setItem("submissionURL", window.location.href)

        const script = document.createElement("script")
        script.key = "factors-ai"
        script.type = "text/javascript"
        script.src = "/scripts/factors-ai.js"
        document.head.appendChild(script)
      }
    } catch (e) {
      console.log("Error: ", e)
    }
  }
}
